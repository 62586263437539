import { Injectable } from '@angular/core';
import {concat, from, Observable, of} from 'rxjs';
import { FileUploader } from 'ng2-file-upload';

import { environment } from '../../../environments/environment';
import { RequestHeaderService } from '../../shared/request-header/request-header.service';
import { CompanyContact } from '../../company/models/company-contact';
import { UploaderService } from '../../course/files/uploader.service';
import {HttpClient} from '@angular/common/http';
import {flatMap, map, toArray} from 'rxjs/operators';

@Injectable()
export class CompanyContactService {

  private readonly url: string = environment.baseUrl + 'companyProfileService/contact';
  private readonly collectionUrl: string = environment.baseUrl + 'companyProfileService/contacts';
  private readonly uploadUrl: string = environment.baseUrl + 'fileService/companyContactImage/';


  private get requestArgs(): {} {
    return {
      headers: this.headerFactory.headerFactory()
    };
  }

  constructor(private http: HttpClient, private headerFactory: RequestHeaderService,
    private uploaderService: UploaderService) {
  }

  public get contacts(): Observable<CompanyContact[]> {
    return this.http.get<CompanyContact[]>(this.collectionUrl, this.requestArgs);
  }

  public getUploaderForContact(id: number): FileUploader {
    const url = this.uploadUrl + id;
    return this.uploaderService.imageUploaderForUrl(url);
  }

  public updateContacts(changedContacts: CompanyContact[]): Observable<CompanyContact[]> {
    const deletedItems = from(changedContacts).pipe(
      flatMap(contact => this.deleteContact(contact)),
      toArray());
    const createdItems = from(changedContacts).pipe(
      flatMap(contact => this.http.post(this.url, contact, this.requestArgs)),
      toArray());
    const updatedItems = from(changedContacts).pipe(
      flatMap(contact => this.http.put(`${this.url}/${contact.id}`, contact, this.requestArgs)),
      toArray());
    return concat(deletedItems, createdItems, updatedItems).pipe(
      toArray(),
      flatMap(responses => this.contacts));
  }

  public saveContact(changedContact): Observable<CompanyContact> {
    if (changedContact.id) {
      return this.http.put(`${this.url}/${changedContact.id}`, changedContact, this.requestArgs).pipe(
        flatMap(response => this.getContact(changedContact.id)));
    } else {
      return this.http.post<any>(this.url, changedContact, this.requestArgs).pipe(
        map(payload => payload.insertedId),
        flatMap(id => this.getContact(id))
      );
    }
  }

  public deleteContact(contact: CompanyContact): Observable<any> {
    const url = `${this.url}/${contact.id}`;
    return this.http.delete(url, this.requestArgs);
  }

  public getContact(id: number): Observable<CompanyContact> {
    if (id >= 0) {
      const url = `${this.collectionUrl}/${id}`;
      return this.http.get<CompanyContact>(url, this.requestArgs);
    } else {
      const contact: CompanyContact = {
        companyUserId: null,
        description: null,
        email: null,
        fax: null,
        fileId: null,
        firstName: null,
        gender: null,
        id: null,
        lastName: null,
        mobile: null,
        phone: null,
        position: null,
        street: null,
        streetNumber: null,
        town: null,
        zip: null,
        birthday: null,
        city: null,
      };
      return of<CompanyContact>(contact);
    }
  }
}
